body {
	font: 14px Arial, sans-serif;
	padding: 0;
	margin: 0;
	color: #545454;
}


.content {
	width: 1000px;
	margin: auto;
}

.content-about 
{
    width: 700px;
	margin: auto;
	line-height: 180%;
}

a {
	text-decoration: none;
	color: #1A0DAB;
}

a:visited, a.visited, a.remembered {
	color: #660099;
}

a:hover {
	text-decoration: underline;
}

.emph {
	font-weight: bold;
}

.box {
	width: 100%;
	margin: auto auto 40px;
}

h1.about {
    padding-top: 15px;
}

label.tag {
    
    padding-top: 15px;
   
}

.hint {
	border: solid;
	border-width: 1px 0 1px 0;
	border-color: #a0a0a0;
	background-color: #f1f1f1;
	padding: 10px 20px;
}

.halfs {
	-webkit-columns: 100px 2; /* Chrome, Safari, Opera */
	-moz-columns: 100px 2; /* Firefox */
	columns: 100px 2;
}

.thirds {
	-webkit-columns: 100px 3; /* Chrome, Safari, Opera */
	-moz-columns: 100px 3; /* Firefox */
	columns: 100px 3;
}

.logo 
{
    text-align: center;
	margin-bottom: 15px;
	margin-top: 130px;
}

.update
{
    width: 50%; 
    text-align: left;   
}

.login 
{
    
	text-align: center;
}

.header-bar  
{
    vertical-align: middle;
    height: 30px;
	background-color: #f1f1f1;
	padding: 5px;
	border-bottom: solid 1px #b9b9b9;
	margin: 0px;
}

.account-info 
{
	float: right;
	margin-right: 20px;
	color: #a0a0a0;
	font-size: 10px;
}

/*
 * Admin Menu
 */
.menu, .actions {
	list-style: none;
	margin: 0;
	padding: 0;
}

.menu li, .actions li {
	display: inline-block;
	cursor: pointer;
}

.menu li:before, .actions li:before {
	content: '|';
}

.menu li:before {
	padding: 3px;
}

.actions li:before {
	padding: 10px;
}

.menu li:first-child:before, .actions li:first-child:before {
	content: '';
	padding: 0;
}

/*
 * Search Page
 */
.search {
	text-align: center;
}

.box .question-words {
	margin-bottom: 0;
}

.question-word {
	font-weight: bold;
}

.question-words {
	margin-bottom: 32px;
}

.question-words .title {
	margin-right: .5em;
}

.question-words span {
	list-style-type: none;
	display: inline-block;
}

.related-terms {
	width: 660px;
	color: #a0a0a0;
}

.related-terms h2 {
	white-space: nowrap;
}

.related-terms li{
	margin-bottom: 6px;
}

.related-terms .question-words,
.related-terms .suggested-terms {
	border-bottom: solid 1px #b9b9b9;
	padding-bottom: 18px;
	margin-bottom: 18px;
}

.related-terms .suggested-terms {
	padding-bottom: 4px;
	margin-bottom: 32px;
}

.related-terms .search-term {
	color: #1A0DAB;
}

.link {
	cursor: pointer;
	color: #1A0DAB;
}

.link:hover {
	text-decoration: underline;
}

.question-words .link:after {
	content: '|';
	padding: 0 .5em;
	color: #b9b9b9;
}

.question-words .link.last:after {
	content: '';
}

.search-form {
	width: 600px;
	margin: 0 auto 80px;
}

.recentQueries {
	width: 650px;
	margin: 0 auto 100px;
	border-top: solid 2px #f1f1f1;
	padding-top: 24px;
	border-top: solid 1px #b9b9b9;
}

.recentQueries table {
	margin: 0 auto;
}

.recentQueries td {
	padding: 0 10px;
}

.recentQueries .heading {
	font-size: 16px;
	font-weight: bold;
	vertical-align: top;
	border-spacing: 21px;
}

.recentQueries .queries {
	text-align: left;
}

.recentQueries .queries div {
	margin-bottom: 12px;
}

/*
 * Auto Complete
 */

.autocomplete {
	position: relative;
}

.autocomplete input {
	width: 100%;
}

.autocomplete ul {
	list-style-type: none;
	margin: 0;
	padding: 0;
	background: #FFF none repeat scroll 0 0;
	border: 1px solid #CCC;
	border-top-color: #D9D9D9;
	-moz-border-top-colors: none;
	-moz-border-right-colors: none;
	-moz-border-bottom-colors: none;
	-moz-border-left-colors: none;
	border-image: none;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
	cursor: default;
	z-index: 999;
	position: absolute;
	width: 100%;
}

.autocomplete li {
	text-align: left;
	line-height: 22px;
	overflow: hidden;
	padding: 0 10px;
}

.autocomplete li.active {
	background-color: #d9d9d9;
}

/*
 * Result Page
 */
.header {
	vertical-align: middle;
	background-color: #f1f1f1;
	padding: 12px;
	border-bottom: solid 1px #b9b9b9;
}

.header img {
	display: inline-block;
}

.header form {
	display: inline-block;
}

.header .autocomplete {
	display: inline-block;
}

.header .autocomplete input {
	width: 600px;
}

.footer {
	margin-top: 100px;
}

.content.result {
	margin-left: 120px;
}

.suggested-terms {
	margin-bottom: 12px;
}

.suggested-terms ul {
	list-style: none;
}

.items {
	list-style: none;
	padding-left: 30px;
}

.items li {
	margin-bottom: 30px;
	height: 100px;
	position: relative;
}

.items .item {
	padding-left: 24px;
	padding-bottom: 5px;
}

.item .container {
	width: 505px;
}

.items h3 {
	font-weight: normal;
	font-size: 18px;
	line-height: 18px;
	padding-bottom: 3px;
	padding-top: 6px;
	margin: 0 10px 0 0;
}

.items .item h3 {
	white-space: nowrap;
	overflow: hidden;
}

.items .target {
	color: #00821e; /* green */
	display: block;
	font-size: 14px;
	font-style: normal;
	white-space: nowrap;
	overflow: hidden;
	margin: 0 10px 3px 0;
}

.items .snippet {
	height: 50px;
	overflow: hidden;
}

/*
 * Wikipedia snippet
 */
.wikipedia {
	border-top: solid 1px rgba(160, 160, 160, 0.5);
	z-index: 555;
}

.wikipedia .item {
	box-shadow: 2px 2px 5px -2px rgba(160, 160, 160, 0.5);
}

.wikipedia-sidebar {
	float: right;
	width: 340px;
	display: block;
	background-color: #FFFFFF;
	-webkit-box-shadow: 2px 2px 5px rgba(160, 160, 160, 0.5);
	-moz-box-shadow: 2px 2px 5px rgba(160, 160, 160, 0.5);
	box-shadow: 2px 2px 5px rgba(160, 160, 160, 0.5);
}

.wikipedia-sidebar .content {
	width: 100%;
	margin-bottom: 12px;
	margin-left: 6px;
	margin-right: 6px;
}

.wikipedia-sidebar h3 {
	font-weight: bold;
	margin: 12px 6px;
}

.wikipedia-sidebar p {
	margin: 0 9px;
}

/*
 * Marker
 */
.marker {
	width: 14px;
	height: 100px;
	position: absolute;
	left: 0;
	top: 0;
	cursor: pointer;
}

.marker:hover {
	filter: brightness(0.8);
}

/*
 * Pagination
 */
.pagination {
	color: #1A0DAB;
	width: 550px;
	list-style-type: none;
	margin-top: 75px;
	text-align: center;
	padding-top: 24px;
	border-top: solid 1px #b9b9b9;
}

.pagination li {
	display: inline-block;
	margin: 0 6px;
	cursor: pointer;
}

.pagination li:hover {
	text-decoration: underline;
}

.pagination li.active {
	cursor: none;
	font-weight: bold;
}

.pagination li.active:hover {
	text-decoration: none;
}

/*
 * Tables
 */

table {
	border-collapse: collapse;
}

td, th {
	padding: 3px;
}

/*
 * Forms
 */
label 
{
    font-weight: bold;
    padding-bottom: 5px;
	font-size: 12px;
	display: block;
}

input, textarea {
	width: 600px;
}

input-update{
	width: 100%;
}

input.study{
    margin-bottom: 5px;
}

input {
	height: 30px;
}

textarea {
	height: 60px;
}

.field 
{
	margin-top: 15px;
}

/*
 * Search Form
 */
input {
	width: 100%;
	height: 30px;
	box-sizing: border-box;
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	border: 1px solid #BEBEBE;
	padding: 7px;
	margin: 0;
	outline: none;
}

.buttons
{
    text-align: center;
}
.button {
	font-size: 12px;
	font-weight: bold;
	color: #333333;
	background-color: rgba(0, 0, 0, 0.1);
	width: auto;
	min-width: 120px;
	padding: 5px;
	margin: 30px 0px 10px;
	border: solid 1px #b9b9b9;
	border-radius: 2px;
	display: inline-block;
}

.button:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.button-login {
	font-size: 12px;
	font-weight: bold;
	color: #ffffff;
	background-color: #0968f1;
	width: auto;
	min-width: 120px;
	padding: 5px;
	margin: 30px 35px 10px;
	border: solid 1px #b9b9b9;
	border-radius: 2px;
	display: inline-block;
}

/* Hover effect */
.button-login:hover {
	background-color: #0753c0;
  }

.button-header {
	font-size: 12px;
	font-weight: bold;
	color: #333333;
	margin-top: 5px;
	background-color: rgba(0, 0, 0, 0.1);
	width: auto;
	min-width: 120px;
	padding: 5px;
	border: solid 1px #b9b9b9;
	border-radius: 2px;
	display: inline-block;
}

.button-header:hover {
	background-color: rgba(0, 0, 0, 0.2);
}

.button-search {
	width: 50px;
	height: 25px;
	border: none;
	padding: 0;
	margin: 0 0 0 -5px;
	vertical-align: top;
	display: inline-block;
}

.button-search:hover{
	filter: brightness(0.8);
}

.menu {
	width: 150px;
	font-family:Arial, Helvetica, sans-serif;
	float: right;
	height: auto;
	margin: 0px;
	padding: 0px;
}

.menuItem {
	width: 150px;
	height: 25px;
	background-color: #f1f1f1;
	border-bottom: solid 1px #b9b9b9;
	padding-left: 10px;
}

.results-search-form {
width: 400px;
height: 25px;
display: inline-block;
}

.results-search {
width: fit-content;
display: inline-block;
vertical-align: top;
margin-top: 5px;
margin-left: 50px;
}



.tabs {
	width: 650px;  
	float: none;
	list-style: none;
	position: relative;
	margin: 80px 0 0 10px;
	text-align: left;
	li {
	  float: left;
	  display: block;
	}
	input[type="radio"] {
	  position: absolute;
	  top: 0;
	  left: -9999px;
	}
	label {
	  display: block;
	  padding: 14px 21px;
	  border-radius: 2px 2px 0 0;
	  font-size: 20px;
	  font-weight: normal;
	  text-transform: uppercase;
	  background: white;
	  cursor: pointer;
	  position: relative;
	  top: 4px; 
	}
	.tab-content{
	  z-index: 2;
	  display: none; 
	  overflow: hidden;
	  width: 100%;
	  font-size: 17px;
	  line-height: 25px;
	  padding: 25px;  
	  position: absolute;
	  top: 53px;
	  left: 0; 
	}
	
	[id^="tab"]:checked + label { 
	  top: 0;
	  padding-top: 17px; 
	  background: rgb(212, 211, 211); 
	}
	[id^="tab"]:checked ~ [id^="tab-content"] {
	  display: block;
	}
  }

  .styled-anchor {
	color: blue;
	text-decoration: underline;
	cursor: pointer;
}
.styled-anchor:hover {
	color: darkblue;
}

.links{
	width : 50vw;
	background-color: #ABABABAA;
	margin: auto;
	list-style: none;
	padding-left: 30px;
	height: 100%;
}

.link{
	margin-bottom: 4px;
	margin-top: 4px;
}

.flagged-links-marker{
	width: 14px;
	height: 50%;
	position: absolute;
	cursor: pointer;
}

.results-container {
    display: flex;
    align-items: flex-start; /* Align items to the top */
}

.items {
    flex: 1;
    list-style: none;
    padding: 0;
}

.sidebar {
    width: 300px;
    padding: 15px;
    background-color: #f8f9fa;
    border: 1px solid #dee2e6;
    margin-left: 20px; /* Add margin to the left to separate it from the results */
    align-self: flex-start; /* Ensure the sidebar does not stretch to fill the height */
}

.sidebar img {
    width: 100%;
    height: auto;
    margin-bottom: 15px;
}

.sidebar h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
}

.sidebar p {
    margin-bottom: 10px;
}

.sidebar a {
    color: #007bff;
    text-decoration: none;
}

.sidebar a:hover {
    text-decoration: underline;
}

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.item {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}

.target, .snippet {
    margin-top: 5px;
}